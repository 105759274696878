<template>
  <v-card-actions class="ButtonStepperActions">
    <v-spacer />
    <v-btn
      v-if="showPrevious"
      text
      v-text="previousText ? previousText:'Anterior'"
      @click="$emit('previous',true)"
      :disabled="disabledPrevious"
    />
    <v-btn
      v-if="showNext || showSave"
      color="primary"
      depressed
      @click="$emit('next', true)"
      :disabled="disabledNext"
    >
      {{_getText()}}
    </v-btn>
  </v-card-actions>
</template>
<script>
export default {
  name: "ButtonStepperActions",
  props: {
    showPrevious: Boolean,
    showNext: Boolean,
    showSave: Boolean,
    saveText: String,
    previousText: String,
    disabledPrevious: Boolean,
    disabledNext: Boolean,
  },
  methods: {
    _getText() {
      if (this.showNext) {
        return "Siguiente";
      } else {
        if (this.saveText) {
          return this.saveText;
        } else {
          return "Finalizar";
        }
      }
    },
  },
};
</script>